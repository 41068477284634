import { SVGProps } from 'react'

export type DataCoalitionsLogoProps = {
  props?: React.SVGProps<SVGSVGElement>
}

export default function DataCoalitionsLogoNoText(props: Omit<SVGProps<SVGSVGElement>, 'dangerouslySetInnerHTML'>) {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 350" {...props}>
      <g transform="translate(0 -100)">
        <path
          d="M318.06 266.42c0 90.1-60.24 132.46-128.68 132.46H72.79v-57.19h90.83c51.51 0 86.31-10.93 86.31-75.27s-34.8-75.27-86.31-75.27H72.79v-57.19h116.59c68.44 0 128.68 42.37 128.68 132.46Z"
          style={{
            fill: '#61deb0'
          }}
        />
        <path
          d="M321.94 269.36c0 90.1 60.24 132.46 128.68 132.46h116.59v-57.19h-90.83c-51.51 0-86.31-10.93-86.31-75.27s34.8-75.27 86.31-75.27h90.83V136.9H450.62c-68.44 0-128.68 42.37-128.68 132.46Z"
          style={{
            fill: '#26b5b8'
          }}
        />
      </g>
    </svg>
  )
}
