'use client'

import { ApolloError, gql, useQuery } from '@apollo/react-hooks'
import { User } from '~/lib/generated/graphql'

import { useAppContext } from '~/components/wrapper/appContextProvider'
import { useSearchParams } from 'next/navigation'
import { on } from 'events'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      displayName
      email
      primaryOrganization {
        id
        active
        displayName
      }
      permissions {
        platformPermissions {
          id
          code
          displayName
        }
        organizationPermissions {
          organization {
            id
            displayName
          }
          permissions {
            id
            code
            displayName
          }
        }
        coalitionPermissions {
          coalition {
            id
            displayName
            type
          }
          permissions {
            id
            code
            displayName
          }
        }
      }
      organizations {
        id
        displayName
      }
      userRoles {
        id
        code
        displayName
      }
    }
  }
`

type UserCurrentUserOptions = {
  onCompleted?: (currentUser: User) => void
}

type UseCurrentUserReturnType = {
  loading: boolean
  error?: ApolloError | undefined
  currentUser?: User
}

export function useCurrentUser(options?: UserCurrentUserOptions): UseCurrentUserReturnType {
  const { data, loading, error } = useQuery<{ currentUser: User }>(CURRENT_USER, {
    // @ts-ignore
    onCompleted: (data) => options?.onCompleted?.(data.currentUser)
  })

  return loading
    ? { loading: true }
    : error
    ? { loading: false, error: error }
    : {
        loading: false,
        currentUser: data?.currentUser
      }
}
