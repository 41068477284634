'use client'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/react-hooks'
import { useSession } from 'next-auth/react'

export const apolloClient = (uri: string, token: string): ApolloClient<NormalizedCacheObject> => {
  const link = new HttpLink({
    uri: uri,
    credentials: 'same-origin',
    headers: {
      authorization: !token || token.trim() === '' ? '' : `bearer ${token}`
    }
  })

  return new ApolloClient({
    link: link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            iconAssets: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return [...existing, ...incoming]
              }
            }
          }
        }
      }
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'ignore'
      }
    }
  })
}

export default function ApolloWrapper({ uri, children }: { uri: string; children: React.ReactNode }) {
  const { data } = useSession()

  return (
    <ApolloProvider client={apolloClient(uri, data?.user.id ?? '')}>
      <>{children}</>
    </ApolloProvider>
  )
}
