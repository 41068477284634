import { ApolloClient, gql } from '@apollo/react-hooks'
import { User } from '../generated/graphql'
import {
  CoalitionPermission,
  CoalitionPermissionType,
  GlobalPermissionType,
  OrgPermission,
  OrgPermissionType
} from './permissions'

export const COALITION_PERMISSIONS = gql`
  query Coalition($coalitionId: String!) {
    coalition(id: $coalitionId) {
      id
      coalitionUserRoles {
        id
        users {
          id
          displayName
          email
        }
      }
    }
  }
`

// export const assertCoalitionP = async (client: ApolloClient<object>, coalitionPerm: CoalitionPermissionType) => {
//   const
//   // let assert =
//   // user.permissions?.coalitionPermissions
//   //   .find((coalitionPerm) => coalitionPerm.coalition.id === coalitionId)
//   //   ?.permissions?.findIndex((perm) => perm.code === permission) ?? -1
// return assert >= 0
// }
//}

export const assertOrganizationPermission = (orgId: string, permission: OrgPermissionType, user?: User): boolean => {
  if (!user) return false
  let assert =
    user.permissions?.organizationPermissions
      .find((orgPerm) => orgPerm.organization.id === orgId)
      ?.permissions?.findIndex((perm) => perm.code === permission) ?? -1
  return assert >= 0
}

export const assertOrganizationPermissions = (
  orgId: string,
  permissions: OrgPermissionType[],
  type: 'any' | 'all',
  user?: User
): boolean => {
  let asserts = permissions.map((permission) => assertOrganizationPermission(orgId, permission, user))

  return type === 'any' ? asserts.includes(true) : !asserts.includes(false)
}

export const assertCoalitionPermission = (coalitionId: string, permission: CoalitionPermissionType, user?: User): boolean => {
  if (!user) return false
  let assert =
    user.permissions?.coalitionPermissions
      .find((coalitionPerm) => coalitionPerm.coalition.id === coalitionId)
      ?.permissions?.findIndex((perm) => perm.code === permission) ?? -1
  return assert >= 0
}

export const assertCoalitionPermissions = (
  coalitionId: string,
  permissions: CoalitionPermissionType[],
  type: 'any' | 'all',
  user?: User
): boolean => {
  let asserts = permissions.map((permission) => assertCoalitionPermission(coalitionId, permission, user))

  return type === 'any' ? asserts.includes(true) : !asserts.includes(false)
}

export const assertGlobalPermission = (permission: GlobalPermissionType, user?: User): boolean => {
  if (!user) return false
  let assert = user.permissions?.platformPermissions.findIndex((gp) => gp.code === permission) ?? -1

  return assert >= 0
}
